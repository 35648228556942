import { Domains } from '../../models/domainConstants';

export const accessDeniedRoute = (): string => '/accessDenied';
export const landingRoute = (): string => '/';

export interface ServiceDetailRouteParams {
  serviceOid: string;
}

export const supportRoute = (): string => '/support';
export const actionCenterRoute = (): string => '/action-center';
export const controlEvidenceRoute = (): string => '/control-evidence';
export const offeringsRoute = (): string => '/offerings';
export const submitOfferingRoute = (): string => '/submit-offering';
export const servicesRoute = (): string => '/service-insights';
export const controlCatalogsLandingRoute = (): string => '/control-catalogs-landing';
export const controlCatalogsRoute = (): string => '/control-catalogs';
export const serviceEvidenceRoute = (): string => '/service-evidence';
export const createEvidencePackageRoute = (): string => '/create-evidence-package';
export const serviceDetailRoute = (params?: ServiceDetailRouteParams): string => `/services/${params?.serviceOid || ':serviceOid'}`;
export const securityPlanManager = (): string => '/security-plan-manager';
export const accessManagementRoute = (): string => '/access-management';
export const permissionsRoute = (): string => '/access-management/permissions';
export const requestsRoute = (): string => '/access-management/requests';
export const controlMetadataListRoute = (): string => '/control-metadata';
export const metricsDashboardRoute = (): string => '/metrics-dashboard';
export const continuousMonitoringRoute = '/conmon';
export const atoCopilotRoute = (): string => '/ato-copilot';

export interface EvidenceDetailRouteParams {
  serviceOid: string;
  evidencePackageId: string;
  navMenuTab: string;
}

export const evidenceDetailRoute = (params?: EvidenceDetailRouteParams): string =>
  `/services/${params?.serviceOid || ':serviceOid'}/evidence-packages/${params?.evidencePackageId || ':evidencePackageId'}/${params?.navMenuTab === '' ? Domains.SERVICE_TREE_PROPERTIES : params?.navMenuTab || ':navMenuTab'}`;

export interface OfferingDetailRouteParams {
  offeringId: string;
  cloud: string;
  certification: string;
}

export const offeringDetailRoute = (params?: OfferingDetailRouteParams): string =>
  `/offerings/${params?.offeringId || ':offeringId'}/${params?.cloud || ':cloud'}/${params?.certification || ':certification'}`;

export interface ControlCatalogRouteParams {
  controlCatalogId: string;
}

export const controlCatalogRoute = (params?: ControlCatalogRouteParams): string =>
  `/control-catalogs/${params?.controlCatalogId || ':controlCatalogId'}`;

export interface ControlMetadataRouteParams {
  controlId: string;
}

export const controlMetadataRoute = (params?: ControlMetadataRouteParams): string => `/control-metadata/${params?.controlId || ':controlId'}`;

export interface SystemDetailsRouteParams {
  systemId?: string | undefined;
  snapshotId?: string | undefined;
}

export interface TenantGoveranceDetailsRouteParams {
  tenantId: string;
  monitoringScanId: string;
}

export const organizationsRoute = (): string => '/organizations';
export const organizationDetailsRoute = (organizationId: string | null): string => `/organizations/${organizationId || ':id'}`;
export const reportsRoute = (): string => '/reports';
export const systemsRoute = (): string => '/systems';
export const tenantGovernanceRoute = (): string => '/tenant-governance';
export const tenantGovernanceDetailRoute = (params?: TenantGoveranceDetailsRouteParams): string =>
  `/tenant-governance/${params?.tenantId || ':tenantId'}/monitoringScans/${params?.monitoringScanId || ':monitoringScanId'}`;
export const organizationFrontMatterRoute = (): string => '/organization-front-matter';
export const systemCreateRoute = (): string => '/systems/create';
export const systemDetailRoute = (params?: SystemDetailsRouteParams): string => `/systems/${params?.systemId || ':systemId'}`;
export const systemSnapshotRoute = (params?: SystemDetailsRouteParams): string =>
  `/systems/${params?.systemId || ':systemId'}/snapshots/${params?.snapshotId || ':snapshotId'}`;
export const evidenceLibraryRoute = (): string => '/evidence-library';

