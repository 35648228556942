import { IColumn, mergeStyles } from '@fluentui/react';
import { Poam } from 'generated/clientApi';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { riskColors } from './poamTab';
import { PoamColumnNames, PoamColumns } from './poamTable';
import { baseSortableColumn, handleServerSideTableSort } from '../components/tableUtils';

export const formatRiskScoreWithColor = (riskScore?: string): JSX.Element => {
  const rating = getRatingFromScore(riskScore);
  if (rating === 'None') {
    return <></>;
  }
  return formatRiskWithColor(rating, riskScore);
};

export const formatRiskWithColor = (riskRating: string, riskScore?: string): JSX.Element => {
  const ogRisk = (riskRating.toLowerCase() ?? '') as keyof typeof riskColors;
  const circleColor = Object.hasOwn(riskColors, ogRisk) ? riskColors[ogRisk] : 'white';
  const circleStyle = mergeStyles({
    width: '9px',
    height: '9px',
    borderRadius: '50%',
    backgroundColor: circleColor,
  });
  return (
    <div style={{ display: 'flex', gap: '0.4rem', alignItems: 'center' }}>
      <div className={circleStyle} />
      <span>{riskRating}</span>
      {riskScore && <span>{`(${riskScore})`}</span>}
    </div>
  );
};

export const formatRiskWithoutColor = (riskRating: string, riskScore?: string): string => `${riskRating}${riskScore ? ` (${riskScore})` : ''}`;

export const getRatingFromScore = (riskScore?: string): string => {
  const numericValue: number = parseFloat(riskScore ?? '');
  if (Number.isNaN(numericValue)) {
    return 'None';
  }
  let rating = 'High';
  if (numericValue < 4) {
    rating = 'Low';
  } else if (numericValue < 7) {
    rating = 'Moderate';
  }
  return rating;
};

type PoamTableColumnsProps = {
  poamColumns: (keyof PoamColumns)[];
  sortTable: (sortColumn: IColumn | null, sortDirection: 'asc' | 'desc') => void;
};
export const PoamTableColumns = (props: PoamTableColumnsProps): IColumn[] => {
  const { poamColumns, sortTable } = props;
  const history = useHistory();

  const updateRouteWithPoam = (poam: Poam) => {
    const { pathname, search } = history.location;

    const newUrl = `${pathname}/${poam.id}/${poam.period}${search}`;
    history.push(newUrl);
  };

  const [columns, setColumns] = useState<IColumn[]>([]);

  const initialColumns: IColumn[] = [
    {
      key: PoamColumnNames['POA&M ID'],
      name: 'POA&M ID',
      minWidth: 100,
      maxWidth: 200,
      fieldName: 'id',
      isMultiline: true,
      ...baseSortableColumn,
      onColumnClick: (ev, column) => handleServerSideTableSort(column, sortTable, setColumns),
      onRender: (item: Poam) => (
        <button
          type="button"
          onClick={() => updateRouteWithPoam(item)}
          style={{
            all: 'unset',
            textDecorationLine: 'underline',
            cursor: 'pointer',
          }}
        >
          {item.id}
        </button>
      ),
    },
    {
      key: PoamColumnNames['Vuln ID'],
      name: 'Vuln ID',
      fieldName: 'vulnerabilityId',
      minWidth: 100,
      maxWidth: 100,
      isMultiline: true,
      ...baseSortableColumn,
      onColumnClick: (ev, column) => handleServerSideTableSort(column, sortTable, setColumns),
    },
    {
      key: PoamColumnNames['Cloud type'],
      name: 'Cloud type',
      fieldName: 'cloudType',
      minWidth: 100,
      maxWidth: 100,
      ...baseSortableColumn,
      onColumnClick: (ev, column) => handleServerSideTableSort(column, sortTable, setColumns),
    },
    {
      key: PoamColumnNames['Scan type'],
      name: 'Scan type',
      fieldName: 'scanType',
      minWidth: 100,
      maxWidth: 110,
      ...baseSortableColumn,
      onColumnClick: (ev, column) => handleServerSideTableSort(column, sortTable, setColumns),
    },
    {
      key: PoamColumnNames.Title,
      name: 'Title',
      fieldName: 'title',
      minWidth: 100,
      maxWidth: 300,
      isMultiline: true,
    },
    {
      key: PoamColumnNames.Status,
      name: 'Status',
      fieldName: 'slaState',
      minWidth: 125,
      maxWidth: 150,
      ...baseSortableColumn,
      onColumnClick: (ev, column) => handleServerSideTableSort(column, sortTable, setColumns),
    },
    {
      key: PoamColumnNames.Due,
      name: 'Due',
      fieldName: 'dueDate',
      minWidth: 100,
      maxWidth: 100,
      ...baseSortableColumn,
      onColumnClick: (ev, column) => handleServerSideTableSort(column, sortTable, setColumns),
    },
    {
      key: PoamColumnNames['Original risk'],
      name: 'Org Risk',
      fieldName: 'originalRisk',
      minWidth: 150,
      maxWidth: 200,
      onRender: (item: Poam) => formatRiskWithColor(item.originalRating ?? '', item.originalRiskScore),
      ...baseSortableColumn,
      onColumnClick: (ev, column) => handleServerSideTableSort(column, sortTable, setColumns),
    },
    {
      key: PoamColumnNames['Effective risk'],
      name: 'Effective risk',
      fieldName: 'effectiveRating',
      minWidth: 100,
      maxWidth: 150,
      onRender: (item: Poam) => formatRiskWithColor(item.effectiveRating ?? '', undefined),
      ...baseSortableColumn,
      onColumnClick: (ev, column) => handleServerSideTableSort(column, sortTable, setColumns),
    },
    {
      key: PoamColumnNames.FP,
      name: 'FP',
      fieldName: 'fp',
      minWidth: 50,
      maxWidth: 75,
      ...baseSortableColumn,
      onColumnClick: (ev, column) => handleServerSideTableSort(column, sortTable, setColumns),
    },
    {
      key: PoamColumnNames.OR,
      name: 'OR',
      fieldName: 'or',
      minWidth: 50,
      maxWidth: 75,
      ...baseSortableColumn,
      onColumnClick: (ev, column) => handleServerSideTableSort(column, sortTable, setColumns),
    },
    {
      key: PoamColumnNames.RA,
      name: 'RA',
      fieldName: 'ra',
      minWidth: 50,
      maxWidth: 75,
      ...baseSortableColumn,
      onColumnClick: (ev, column) => handleServerSideTableSort(column, sortTable, setColumns),
    },
    {
      key: PoamColumnNames['Planned remediation'],
      name: 'Planned remediation',
      minWidth: 125,
      maxWidth: 150,
      fieldName: 'remediationInfo.plannedRemediationDate',
      onRender: (item: Poam) => <div>{item.remediationInfo?.milestoneHistory?.at(-1)?.plannedRemediationDate}</div>,
    },

    {
      key: PoamColumnNames.CVEs,
      name: 'CVEs',
      fieldName: 'cveCount',
      minWidth: 50,
      maxWidth: 60,
      ...baseSortableColumn,
      onColumnClick: (ev, column) => handleServerSideTableSort(column, sortTable, setColumns),
    },
    {
      key: PoamColumnNames['Control Id'],
      name: 'Control Id',
      fieldName: 'controlId',
      minWidth: 75,
      maxWidth: 120,
      ...baseSortableColumn,
      onColumnClick: (ev, column) => handleServerSideTableSort(column, sortTable, setColumns),
    },
    {
      key: PoamColumnNames['Planned remediation comments'],
      name: 'Planned remediation comments',
      minWidth: 150,
      maxWidth: 220,
      isMultiline: true,
      onRender: (item: Poam) => <div>{item.remediationInfo?.milestoneHistory?.at(-1)?.comment}</div>,
    },
    {
      key: PoamColumnNames['Vendor dependency'],
      name: 'Vendor dependency',
      minWidth: 120,
      maxWidth: 130,
      onRender: (item: Poam) => <div>{item.remediationInfo?.vendorDependency}</div>,
    },
    {
      key: PoamColumnNames['Vendor Check-in Date'],
      name: 'Vendor check-in',
      fieldName: 'vendor Check-in Date',
      minWidth: 120,
      maxWidth: 120,
      onRender: (item: Poam) => <div>{item.remediationInfo?.checkInDate}</div>,
    },
    {
      key: PoamColumnNames['Product name'],
      name: 'Product name',
      minWidth: 150,
      maxWidth: 150,
      isMultiline: true,
      onRender: (item: Poam) => <div>{item.remediationInfo?.productName}</div>,
    },
    {
      key: PoamColumnNames['Internal comments'],
      name: 'Internal comments',
      fieldName: 'internalComments',
      minWidth: 150,
      maxWidth: 220,
      isMultiline: true,
    },
    {
      key: PoamColumnNames['Changed by'],
      name: 'Changed by',
      fieldName: 'changedBy',
      minWidth: 150,
      maxWidth: 200,
      isMultiline: true,
    },
    {
      key: PoamColumnNames['Changed date'],
      name: 'Changed date',
      minWidth: 100,
      maxWidth: 150,
      fieldName: 'changedDateUtc',
      isMultiline: true,
      ...baseSortableColumn,
      onColumnClick: (ev, column) => handleServerSideTableSort(column, sortTable, setColumns),
      onRender: (item: Poam) => <>{item.changedDateUtc?.toUTCString()}</>,
    },
  ];

  useEffect(() => {
    const filteredColumns = initialColumns.filter((col) => poamColumns.includes(col.key as keyof PoamColumns));
    setColumns(filteredColumns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [poamColumns]);

  return columns;
};
