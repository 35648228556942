import { useQueryParameters } from 'components/util/useQueryParameters';
import React from 'react';

export type PoamFilters = {
  plannedRemediationComments: string;
  plannedRemediation: string;
  vendorCheckInDate: string;
  vendorDependency: string;
  internalComments: string;
  controlId: string;
  effectiveRisk: string;
  originalRisk: string;
  showClosed: boolean;
  poamVulnId: string;
  cloudType: string;
  slaState: string;
  scanType: string;
  period: string;
  state: string;
  due: string;
  fp: string;
  or: string;
  ra: string;
};
/**
 * Parses URL and converts query params into a filter object, using default values where necessary.
 */
export const usePoamFilters = (currentPeriod?: string): PoamFilters => {
  const queryParams = useQueryParameters();

  const plannedRemediationComments = queryParams.plannedRemediationComments as string;
  const plannedRemediation = queryParams.plannedRemediation as string;
  const vendorCheckInDate = queryParams.vendorCheckInDate as string;
  const vendorDependency = queryParams.vendorDependency as string;
  const internalComments = queryParams.internalComments as string;
  const controlId = queryParams.controlId as string;
  const showClosed = (queryParams.showClosed ?? false) as boolean;
  const period = (queryParams.period as string) ?? currentPeriod;
  const effectiveRisk = queryParams.effectiveRisk as string;
  const originalRisk = queryParams.originalRisk as string;
  const poamVulnId = queryParams.poamVulnId as string;
  const cloudType = queryParams.cloudType as string;
  const slaState = queryParams.slaState as string;
  const scanType = queryParams.scanType as string;
  const state = queryParams.state as string;
  const due = queryParams.due as string;
  const fp = queryParams.fp as string;
  const or = queryParams.or as string;
  const ra = queryParams.ra as string;

  return React.useMemo(
    () => ({
      ra,
      or,
      fp,
      due,
      state,
      period,
      scanType,
      slaState,
      cloudType,
      showClosed,
      poamVulnId,
      originalRisk,
      effectiveRisk,
      internalComments,
      controlId,
      vendorDependency,
      vendorCheckInDate,
      plannedRemediation,
      plannedRemediationComments,
    }),
    [
      ra,
      or,
      fp,
      due,
      state,
      period,
      scanType,
      slaState,
      cloudType,
      showClosed,
      poamVulnId,
      originalRisk,
      effectiveRisk,
      internalComments,
      controlId,
      vendorDependency,
      vendorCheckInDate,
      plannedRemediation,
      plannedRemediationComments,
    ],
  );
};
