import {
    AtoCopilotResponse,
    AtoCopilotGetClient,
    AtoCopilotGetRequestBody
} from '../../generated/clientApi';
import { getConfig } from '../config/config';

export const getAtoCopilotResponse = async (subscriptionId: string, controlId: string, userToken: string, azureSsp: string): Promise<AtoCopilotResponse> => {
    const atoCopilotGetClient = new AtoCopilotGetClient(getConfig().apiBaseUri);
    const atoCopilotRequestBody = new AtoCopilotGetRequestBody();
    atoCopilotRequestBody.userToken = userToken;
    atoCopilotRequestBody.azureSsp = azureSsp;
    return atoCopilotGetClient.get(controlId, subscriptionId, atoCopilotRequestBody);
};