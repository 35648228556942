import {
  DetailsList,
  DetailsRow,
  IColumn,
  IDetailsRowProps,
  IScrollablePaneStyles,
  Selection,
  ScrollablePane,
  ScrollbarVisibility,
  SelectionMode,
  Spinner,
  SpinnerSize,
  mergeStyles,
  IObjectWithKey,
  Checkbox,
  IDetailsHeaderProps,
  IRenderFunction,
  Sticky,
  StickyPositionType,
} from '@fluentui/react';
import { BasicPoam, SortDirection } from 'generated/clientApi';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PoamTableColumns } from './poamTableColumns';

export const PoamColumnNames = {
  'POA&M ID': 'POA&M ID',
  'Vuln ID': 'Vuln ID',
  'Cloud type': 'Cloud type',
  Title: 'Title',
  Status: 'Status',
  Due: 'Due',
  'Original risk': 'Original risk',
  'Effective risk': 'Effective risk',
  FP: 'FP',
  OR: 'OR',
  RA: 'RA',
  'Control Id': 'Control Id',
  'Planned remediation': 'Planned remediation',
  'Scan type': 'Scan type',
  CVEs: 'CVEs',
  'Planned remediation comments': 'Planned remediation comments',
  'Vendor dependency': 'Vendor dependency',
  'Vendor Check-in Date': 'Vendor Check-in Date',
  'Product name': 'Product name',
  'Internal comments': 'Internal comments',
  'Changed by': 'Changed by',
  'Changed date': 'Changed date',
} as const;

export type PoamColumns = { [K in keyof typeof PoamColumnNames]: K };

export const DefaultPoamColumns: (keyof PoamColumns)[] = [
  'POA&M ID',
  'Vuln ID',
  'Cloud type',
  'Scan type',
  'Title',
  'Status',
  'Due',
  'Original risk',
  'Effective risk',
  'FP',
  'OR',
  'RA',
  'Planned remediation',
];
export const customPoamTableStyles = mergeStyles({
  '.ms-Viewport': {
    minWidth: 'inherit',
  },
  '.ms-Check': {
    cursor: 'pointer',
  },
  '.ms-DetailsRow-fields': {
    userSelect: 'text !important',
  },
  height: 'calc(100vh - (355px))',
  overflow: 'auto',
  width: '100%',
  position: 'relative',
});

export const poamScrollPaneStyles = {
  root: {
    '.ms-DetailsList': {
      overflow: 'visible',
    },
  },
};

const checkboxAllStyles = mergeStyles({
  '.ms-Checkbox-checkbox': {
    borderRadius: '100%',
  },
});

type PoamTableProps = {
  poams: BasicPoam[];
  loading: boolean;
  poamColumns: (keyof PoamColumns)[];
  poamSelection: Map<string, BasicPoam>;
  readonly: boolean;
  setPoamSelection: React.Dispatch<React.SetStateAction<Map<string, BasicPoam>>>;
  onScroll: (event: React.UIEvent<HTMLElement>) => void;
  setSort: React.Dispatch<
    React.SetStateAction<
      | {
          column: keyof BasicPoam;
          direction: SortDirection;
        }
      | undefined
    >
  >;
};
export const PoamTable: React.FunctionComponent<PoamTableProps> = (props) => {
  const { poams, loading, poamColumns, poamSelection, setPoamSelection, setSort, readonly, onScroll } = props;
  const [displayedPoams, setDisplayedPoams] = useState<BasicPoam[]>(poams);

  const [isAllSelected, setAllSelected] = useState(false);

  useEffect(() => {
    setAllSelected(false);
  }, [poams]);

  const onSelectAll = useCallback(
    (isSelected: boolean) => {
      if (isSelected) {
        const newSelection = new Map(poamSelection);
        poams.forEach((poam) => {
          newSelection.set(poam.id, poam);
        });
        setPoamSelection(newSelection);
      } else {
        setPoamSelection(new Map());
      }
      setAllSelected(isSelected);
    },
    [poamSelection, poams, setPoamSelection],
  );

  const selection = useMemo(() => {
    const selection = new Selection<BasicPoam>({
      getKey: (item: any) => item.id,
    });
    return selection;
  }, []);

  useEffect(() => {
    poams.forEach((poam, index) => {
      selection.setIndexSelected(index, poamSelection.has(poam.id), true);
    });
  }, [poamSelection, poams, selection, loading]);

  const sortTable = (sortColumn: IColumn | null, sortDirection: 'asc' | 'desc'): void => {
    if (sortColumn && sortDirection) {
      setSort({
        column: sortColumn.fieldName as keyof BasicPoam,
        direction: sortDirection === 'asc' ? SortDirection.Asc : SortDirection.Desc,
      });
    }
  };

  const columns = PoamTableColumns({ poamColumns, sortTable });

  useEffect(() => {
    setDisplayedPoams(poams);
  }, [poams]);

  const onRenderRow = useCallback(
    (props?: IDetailsRowProps) => {
      if (!props) {
        return <></>;
      }
      return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <div
          onClick={(ev?: any) => {
            if (ev.target.classList.contains('ms-Check-check')) {
              const { id } = props.item ?? '';
              setPoamSelection((prevMap) => {
                const newMap = new Map(prevMap);
                if (newMap.has(id)) {
                  newMap.delete(id);
                } else {
                  newMap.set(id, props.item);
                }
                return newMap;
              });
            }
          }}
        >
          <DetailsRow {...props} data-selection-disabled />
        </div>
      );
    },
    [setPoamSelection],
  );

  const renderPoamFixedDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (props, defaultRender) => {
    if (!props) {
      return null;
    }
    const onRenderDetailsCheckbox = () => (
      <Checkbox
        className={checkboxAllStyles}
        styles={{ root: { paddingLeft: '7px' } }}
        checked={isAllSelected}
        onChange={(ev, checked) => onSelectAll(checked ?? false)}
      />
    );
    // eslint-disable-next-line no-param-reassign
    props.onRenderDetailsCheckbox = onRenderDetailsCheckbox;
    return (
      <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced>
        {defaultRender!({
          ...props,
        })}
      </Sticky>
    );
  };
  return (
    <div className={customPoamTableStyles} onScroll={onScroll}>
      <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto} styles={poamScrollPaneStyles as unknown as IScrollablePaneStyles}>
        <DetailsList
          items={displayedPoams}
          columns={columns}
          selectionMode={readonly ? SelectionMode.none : SelectionMode.multiple}
          selection={selection as Selection<IObjectWithKey>}
          onRenderRow={onRenderRow}
          onRenderDetailsHeader={renderPoamFixedDetailsHeader}
        />
        {loading && <Spinner label="Loading POA&Ms..." size={SpinnerSize.large} />}

        {!loading && (!displayedPoams || displayedPoams.length === 0) && (
          <em style={{ display: 'block', marginTop: '2rem', marginLeft: '2rem' }}>
            No POA&Ms match the given filter criteria. Try clearing or modifying the filter values.
          </em>
        )}
      </ScrollablePane>
    </div>
  );
};
