import download from 'downloadjs';
import { showError } from 'modules/messageBar/messageBar';
import {
  ApiException,
  AuthorizedSystem,
  AvcXactaExcelGetClient,
  EMassExcelGetClient,
  FedRampRevisions,
  ISystemSnapshotPostCommand,
  ISystemSnapshotTagPutCommand,
  MpoXactaExcelGetClient,
  SnapShotOscalGetClient,
  SnapShotWordGetClient,
  SystemGetClient,
  SystemPostClient,
  SystemPostCommand,
  SystemPutClient,
  SystemPutCommand,
  SystemsDeleteClient,
  SystemsDeleteResponse,
  SystemsListClient,
  SystemSnapshot,
  SystemSnapshotDeleteClient,
  SystemSnapshotDeleteResponse,
  SystemSnapshotGetClient,
  SystemSnapshotGetResponse,
  SystemSnapshotListClient,
  SystemSnapshotListResponse,
  SystemSnapshotPostClient,
  SystemSnapshotPostCommand,
  SystemSnapshotPostResponse,
  SystemSnapshotTagPutClient,
  SystemSnapshotTagPutCommand,
} from '../../generated/clientApi';
import { getConfig } from '../config/config';

export const getSystemsList = async (organizationId: string): Promise<AuthorizedSystem[]> => {
  const client = new SystemsListClient(getConfig().apiBaseUri);
  return client.get(organizationId);
};

export const getSystem = async (organizationId: string, systemId: string): Promise<AuthorizedSystem> => {
  const client = new SystemGetClient(getConfig().apiBaseUri);
  return client.get(organizationId, systemId);
};

export const updateSystem = async (organizationId: string, systemId: string, system: SystemPutCommand): Promise<AuthorizedSystem> => {
  const client = new SystemPutClient(getConfig().apiBaseUri);
  return client.put(organizationId, systemId, system);
};

export const createSystem = async (organizationId: string, system: SystemPostCommand): Promise<AuthorizedSystem> => {
  const client = new SystemPostClient(getConfig().apiBaseUri);
  return client.post(organizationId, system);
};

export const deleteSystems = async (systemIds: string[]): Promise<SystemsDeleteResponse> => {
  const client = new SystemsDeleteClient(getConfig().apiBaseUri);
  return client.delete(systemIds);
};

export const getSystemSnapshotsList = async (systemId: string): Promise<SystemSnapshotListResponse[]> => {
  const client = new SystemSnapshotListClient(getConfig().apiBaseUri);
  return client.get(systemId);
};

export const getSystemSnapshot = async (systemId: string, snapshotId: string): Promise<SystemSnapshotGetResponse> => {
  const client = new SystemSnapshotGetClient(getConfig().apiBaseUri);
  return client.get(systemId, snapshotId);
};

export const createSystemSnapshot = async (snapshot: ISystemSnapshotPostCommand): Promise<SystemSnapshotPostResponse> => {
  const client = new SystemSnapshotPostClient(getConfig().apiBaseUri);
  const command = snapshot as SystemSnapshotPostCommand;
  return client
    .create(command.systemId, command)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
};

export const downloadDisaDocument = async (
  organizationId: string,
  systemId: string,
  snapshotId: string,
  systemName: string,
  snapshotName: string,
): Promise<void> => {
  const fileName = `disa_${systemName}_${snapshotName}_${new Date().toISOString()}.xlsx`;
  const client = new EMassExcelGetClient(getConfig().apiBaseUri);
  await client
    .get(systemId, snapshotId, organizationId)
    .then((response) => {
      download(response.data, fileName, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    })
    .catch((error) => {
      throw error;
    });
};

export const downloadRev4WordDocument = async (
  organizationId: string,
  systemId: string,
  snapshotId: string,
  systemName: string,
  snapshotName: string,
): Promise<void> => {
  const fileName = `${systemName}_${snapshotName}_${new Date().toISOString()}.docx`;
  const client = new SnapShotWordGetClient(getConfig().apiBaseUri);
  await client
    .get(systemId, snapshotId, organizationId, FedRampRevisions.Rev4, false)
    .then((response) => {
      download(response.data, fileName, 'application/vnd.ms-word');
    })
    .catch((apiException: ApiException) => {
      throw apiException;
    });
};

export const downloadRev5Zip = async (
  organizationId: string,
  systemId: string,
  snapshotId: string,
  systemName: string,
  snapshotName: string,
  generic: boolean,
): Promise<void> => {
  const fileName = `${systemName}_${snapshotName}_${new Date().toISOString()}.zip`;
  const client = new SnapShotWordGetClient(getConfig().apiBaseUri);
  await client
    .get(systemId, snapshotId, organizationId, FedRampRevisions.Rev5, generic)
    .then((response) => {
      download(response.data, fileName, 'application/zip');
    })
    .catch((apiException: ApiException) => {
      throw apiException;
    });
};

export const downloadOscal = async (
  organizationId: string,
  systemId: string,
  snapshotId: string,
  systemName: string,
  snapshotName: string,
): Promise<void> => {
  const fileName = `${systemName}_${snapshotName}_${new Date().toISOString()}.json`;
  const client = new SnapShotOscalGetClient(getConfig().apiBaseUri);
  await client
    .get(systemId, snapshotId, organizationId)
    .then((response) => {
      download(response.data, fileName, 'application/json');
    })
    .catch((apiException: ApiException) => {
      throw apiException;
    });
};

export const downloadXactaAvcDocument = async (
  organizationId: string,
  systemId: string,
  snapshotId: string,
  systemName: string,
  snapshotName: string,
): Promise<void> => {
  try {
    const fileName = `xacta_avc_${systemName}_${snapshotName}_${new Date().toISOString()}.xlsx`;
    const client = new AvcXactaExcelGetClient(getConfig().apiBaseUri);
    const response = await client.get(systemId, snapshotId, organizationId);
    if (!response) {
      return; // error
    }
    download(response.data, fileName, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
  } catch (ex) {
    showError('There was an issue downloading the Xacta AVC document. Please refresh and try again.');
  }
};

export const downloadXactaMpoDocument = async (
  organizationId: string,
  systemId: string,
  snapshotId: string,
  systemName: string,
  snapshotName: string,
): Promise<void> => {
  try {
    const fileName = `xacta_mpo_${systemName}_${snapshotName}_${new Date().toISOString()}.xlsx`;
    const client = new MpoXactaExcelGetClient(getConfig().apiBaseUri);
    const response = await client.get(organizationId, systemId, snapshotId, true);
    if (!response) {
      return; // error
    }
    download(response.data, fileName, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
  } catch (ex) {
    showError('There was an issue downloading the Xacta MPO document. Please refresh and try again.');
  }
};

export const deleteSystemSnapshot = async (systemId: string, snapshotId: string): Promise<SystemSnapshotDeleteResponse> => {
  const client = new SystemSnapshotDeleteClient(getConfig().apiBaseUri);
  return client.delete(systemId, snapshotId);
};

export const updateSystemSnapshotTags = async (snapshotData: ISystemSnapshotTagPutCommand): Promise<SystemSnapshot> => {
  const client = new SystemSnapshotTagPutClient(getConfig().apiBaseUri);
  const command = snapshotData as SystemSnapshotTagPutCommand;
  return client.create(snapshotData.systemId, snapshotData.snapshotId, command);
};
