import {
  DrReportAnalysisPostClient,
  FileParameter,
  LockedStatusPutClient,
  LockedStatusPutQuery,
  PoamListClient,
  ReportsPostClient,
  PagedResultOfBasicPoam,
  SlaState,
  SortDirection,
  BasicPoam,
  ManualPoamPostClient,
  Poam,
} from 'generated/clientApi';
import { getConfig } from 'modules/config/config';
import { showError, showSuccess } from 'modules/messageBar/messageBar';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ValueOf } from 'components/util/objectUtils';
import { PrimaryButton, Separator, ActionButton, IContextualMenuItem } from '@fluentui/react';
import { useParams } from 'react-router';
import { actionButtonStyles } from 'styles/actionButtonStyles';
import { createContextMenuItem, getMenuProps } from 'modules/contextMenu/contextMenu';
import { FileAttachmentComponent } from 'components/fileAttachment/fileAttachment';
import { ProgressDots } from 'components/progressDots/progressDots';
import { PoamColumns, PoamTable } from './poamTable';
import { PoamRiskBox } from './poamRiskBox';
import { DetailsPanel } from './poamPanel/detailsPanel';
import { PoamFilterBar, PoamFilterNames } from './poamFilterBar';
import { PoamFilters, usePoamFilters } from './usePoamFilters';
import { LockButton } from './lockButton';
import { separatorStyles } from '../conMon';
import { buttonStyles } from '../styles/buttonStyles';
import { ClosedPoamTable } from './closedPoamTable';
import { RiskPanel } from './riskPanel/riskPanel';
import { AutoRiskPanel } from './autoRiskPanel/autoRiskPanel';
import { BulkEditPanel } from './bulkEditPanel/bulkEditPanel';
import { EvidenceDeviationPanel } from './evidenceDeviationPanel';
import { ProposedClosurePanel } from './proposedClosurePanel/proposedClosurePanel';
import { ConMonFilterContext } from '../conMonFilterContext/conMonFilterContext';
import { getDateStringFromFilterValue } from './poamFilterValues';
import { PoamTabActionButtons } from './poamTabActionButtons';
import { ManualPoamModal } from './manualPoamModal/manualPoamModal';

export const riskColors = {
  low: '#199F77',
  moderate: '#FFB900',
  high: '#F25022',
  total: '#605E5C',
};

let timeoutId: NodeJS.Timeout | undefined;
const debounce = (func: (...args: any[]) => void, delay: number) => {
  const debouncedFunction = (...args: any[]) => {
    clearTimeout(timeoutId as NodeJS.Timeout);

    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
  return debouncedFunction;
};

type BasicPoamPageWithCount = Partial<PagedResultOfBasicPoam> & { totalCount: number };

export const poamPanelTypes = {
  manualRisk: 'manualRisk',
  autoRisk: 'autoRisk',
  proposedClosure: 'proposedClosure',
  manualClosure: 'manualClosure',
  bulkEdit: 'bulkEdit',
  falsePositive: 'falsePositive',
  operationalRequirement: 'operationalRequirement',
} as const;

type PoamTabProps = {
  lockedMap: Record<string, boolean>;
  poamColumns: (keyof PoamColumns)[];
  updatePoamColumns: (columns: (keyof PoamColumns)[]) => void;
  poamFilters: ValueOf<typeof PoamFilterNames>[];
  updatePoamFilters: (filters: ValueOf<Partial<typeof PoamFilterNames>>[]) => void;
};
export const PoamTab: React.FunctionComponent<PoamTabProps> = (props) => {
  const { lockedMap, poamColumns, updatePoamColumns, poamFilters, updatePoamFilters } = props;
  const { readonly, organization } = useContext(ConMonFilterContext);
  const periods = useMemo(() => Object.keys(lockedMap), [lockedMap]);
  const [poamResults, setPoamResults] = useState<BasicPoamPageWithCount>({
    results: [],
    continuationToken: '',
    totalCount: 0,
  });
  const [itemCount, setItemCount] = useState(0);
  const [sort, setSort] = useState<{ column: keyof BasicPoam; direction: SortDirection }>();
  const poams = poamResults.results ?? [];
  const { id: selectedPoamId } = useParams<{ id?: string }>();
  const { period: selectedPeriod } = useParams<{ period?: string }>();

  const selectedPoamPeriod = useMemo(
    () => selectedPeriod ?? poamResults.results?.find((poam) => poam.id === selectedPoamId)?.period,
    [poamResults, selectedPeriod, selectedPoamId],
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [isLocking, setIsLocking] = useState<boolean>(false);
  const [openPanelName, setOpenPanelName] = useState<keyof typeof poamPanelTypes>();
  const [poamSelectionMap, setPoamSelectionMap] = useState<Map<string, BasicPoam>>(new Map());
  const poamSelection = useMemo(() => Array.from(poamSelectionMap.values()), [poamSelectionMap]);
  const [localInputRef, setLocalInputRef] = useState<React.RefObject<HTMLInputElement>>();
  const [fileTypeToProcess, setFileTypeToProcess] = useState<'drReport' | 'manualPoams'>();
  const [manualPoams, setManualPoams] = useState<Poam[]>();
  const [loadingPage, setLoadingPage] = useState<boolean>(false);

  const filters = usePoamFilters(periods[0]);
  const isLocked = useMemo(() => {
    const selectedPeriod = filters.period;
    return lockedMap[selectedPeriod] ?? true;
  }, [filters.period, lockedMap]);

  const isEditable = useMemo(() => {
    if (readonly) {
      return false;
    }
    const allMonths = Object.keys(lockedMap);
    if (lockedMap[filters.period]) {
      if (allMonths[0] === filters.period || (allMonths[1] === filters.period && !lockedMap[allMonths[0]])) {
        return true;
      }
    }
    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.period, readonly]);

  const reportMenuItems: IContextualMenuItem[] = [
    createContextMenuItem('uploadDrReport', 'Upload DR report', '', () => {
      localInputRef?.current?.click();
      setFileTypeToProcess('drReport');
    }),
    createContextMenuItem('uploadManualPoams', 'Upload manual POA&Ms', '', () => {
      localInputRef?.current?.click();
      setFileTypeToProcess('manualPoams');
    }),
  ];

  useEffect(() => {
    setPoamSelectionMap(new Map());
  }, [filters.period, organization]);

  useEffect(() => {
    const visibleFilters = Object.entries(filters)
      .map(([key, value]) => {
        if (value !== undefined && key in PoamFilterNames) {
          return PoamFilterNames[key as keyof typeof PoamFilterNames];
        }
        return null;
      })
      .filter(Boolean) as string[];
    const uniqueFilters = Array.from(new Set([...visibleFilters, ...poamFilters])) as ValueOf<Partial<PoamFilters>>[];
    updatePoamFilters(uniqueFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPoams = useCallback(
    async (replace = false) => {
      setLoading(true);
      if (replace) {
        setItemCount(0);
        setPoamResults({ results: [], continuationToken: '', totalCount: 0 });
      }

      const client = new PoamListClient(getConfig().apiBaseUri);
      try {
        const currPeriod = filters.period;
        const currLockStatus = lockedMap[currPeriod];
        const response = await client.get(
          currPeriod,
          organization,
          currLockStatus,
          replace ? '' : poamResults.continuationToken,
          sort?.direction,
          sort?.column,
          filters.poamVulnId,
          filters.cloudType,
          filters.slaState as SlaState,
          getDateStringFromFilterValue(filters.due),
          filters.originalRisk,
          filters.effectiveRisk,
          filters.scanType,
          filters.fp,
          filters.or,
          filters.ra,
          filters.controlId,
          filters.internalComments,
          getDateStringFromFilterValue(filters.plannedRemediation),
          filters.plannedRemediationComments,
          filters.vendorDependency,
          getDateStringFromFilterValue(filters.vendorCheckInDate),
        );
        if (replace) {
          setItemCount(response.page.results?.length ?? 0);
          setPoamResults({
            results: response.page.results ?? [],
            continuationToken: response.page.continuationToken,
            totalCount: response.totalCount,
          });
        } else {
          setItemCount((prev) => prev + (response.page.results?.length ?? 0));
          setPoamResults((prev) => ({
            results: [...(prev?.results ?? []), ...(response.page.results ?? [])],
            continuationToken: response.page.continuationToken,
            totalCount: response.totalCount,
          }));
        }
      } catch (e) {
        showError('There was an error fetching poams. Please refresh to try again.');
      } finally {
        setLoading(false);
      }
    },
    [filters, lockedMap, organization, sort, poamResults.continuationToken],
  );

  useEffect(() => {
    const debouncedFetchData = debounce(() => getPoams(true), 250);
    debouncedFetchData();
    return () => {
      clearTimeout(timeoutId as NodeJS.Timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, organization]);

  useEffect(() => {
    if (sort?.column) {
      getPoams(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort]);

  const onLock = async () => {
    if (!organization) {
      showError('The organization must be selected to lock coverage.');
      return;
    }
    setIsLocking(true);
    const client = new LockedStatusPutClient(getConfig().apiBaseUri);

    const currPeriod = filters.period;
    const errorMessage = `There was an error locking coverage for the period ${currPeriod}. Please try again.`;
    try {
      const response = await client.put(new LockedStatusPutQuery({ period: currPeriod, organization }));
      if (response === true) {
        showSuccess(`Coverage was successfully locked for the period ${currPeriod}`, 5);
        window.location.reload();
      } else {
        showError(errorMessage, 5);
      }
    } catch {
      showError(errorMessage, 5);
    } finally {
      setIsLocking(false);
    }
  };

  const refreshTable = () => {
    setOpenPanelName(undefined);
    getPoams(true);
  };

  const ingestReport = async (file: File | undefined) => {
    if (fileTypeToProcess === 'drReport') {
      await analyzeDrReport(file);
    } else {
      await ingestManualPoams(file);
    }
  };

  const analyzeDrReport = async (file: File | undefined) => {
    if (file) {
      setLoadingPage(true);
      const evidenceClient = new DrReportAnalysisPostClient(getConfig().apiBaseUri);

      try {
        const fileParams = {
          data: file,
          fileName: file.name,
        } as FileParameter;
        const response = await evidenceClient.post(fileParams, filters.period, organization);
        localInputRef!.current!.value = '';
        const processed = response.created?.length ?? 0;
        const errors = response.failed?.length ?? 0;
        showSuccess(`${processed + errors} deviations uploaded. ${processed} successes and ${errors} errors.`, 10);
      } catch (e) {
        showError('There was an error uploading the DR report. Check the file uploaded and the period selected and try again.', 10);
      } finally {
        setLoadingPage(false);
      }
    }
  };

  const ingestManualPoams = async (file: File | undefined) => {
    if (file) {
      setLoadingPage(true);
      const manualPoamClient = new ManualPoamPostClient(getConfig().apiBaseUri);
      try {
        const fileParams = {
          data: file,
          fileName: file.name,
        } as FileParameter;
        const response = await manualPoamClient.post(fileParams, filters.period, organization);

        if (response.errors?.length) {
          showError(`Failed to ingest file with the following errors:\n${response.errors.join('\n')}`);
        } else if (!response.poams?.length) {
          showError(`Failed to parse any POAMs from the file ${file.name}. Please ensure the file is well formatted and try again.`, 10);
        } else {
          setManualPoams(response.poams);
        }
      } catch (e) {
        showError('There was an error ingesting manual POAMs. Please ensure the file is well formatted and try again.', 10);
      } finally {
        localInputRef!.current!.value = '';
        setLoadingPage(false);
      }
    }
  };

  const generateReport = async () => {
    setLoadingPage(true);
    const timeoutPromise = new Promise<void>((resolve) => {
      setTimeout(() => {
        resolve();
      }, 7000);
    });

    const reportClient = new ReportsPostClient(getConfig().apiBaseUri);
    try {
      await Promise.race([reportClient.post(filters.period, organization), timeoutPromise]);
      showSuccess('Reports are being generated. Please check the reports tab to view progress.', 10);
    } catch (e) {
      showError('There was an error generating the reports. Please try again.', 10);
    } finally {
      setLoadingPage(false);
    }
  };

  const onScrollReachedBottom = useCallback(() => {
    if (loading === false && !!poamResults?.continuationToken) {
      getPoams();
    }
  }, [getPoams, loading, poamResults?.continuationToken]);

  const onScroll = (event: React.UIEvent<HTMLElement>): void => {
    const target = event.target as HTMLInputElement;
    if (target.scrollTop > 0 && target.scrollHeight - target.scrollTop <= 1.05 * target.clientHeight) {
      onScrollReachedBottom();
    }
  };

  return (
    <div>
      {loadingPage && (
        <div
          style={{
            position: 'absolute',
            height: '100vh',
            width: '100vw',
            backgroundColor: 'rgba(0,0,0,.2)',
            backdropFilter: 'blur(5px)',
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
            zIndex: 101,
            top: 0,
            left: 0,
          }}
        >
          <ProgressDots />
        </div>
      )}
      <PoamRiskBox currentPeriod={filters.period} isLocked={lockedMap[filters.period] ?? false} cloud={filters.cloudType} />
      <div
        style={{
          display: 'flex',
          gap: '1rem',
          position: 'sticky',
          top: '55px',
          width: '100%',
          background: 'white',
          zIndex: 100,
        }}
      >
        {isLocked ? (
          <PrimaryButton style={{ ...buttonStyles, margin: '.5rem 0' }} iconProps={{ iconName: 'lock' }} onClick={generateReport} disabled={readonly}>
            Generate report
          </PrimaryButton>
        ) : (
          <LockButton onLock={onLock} isLocking={isLocking} isDisabled={readonly} />
        )}
        {isEditable && (
          <>
            <ActionButton
              style={{ height: '32px', margin: '.5rem 0' }}
              className={actionButtonStyles}
              text="Upload Reports"
              menuProps={getMenuProps(reportMenuItems)}
            />
            <FileAttachmentComponent
              disableUpload={false}
              allowedExtensions={['.xls', '.xlsx', '.csv']}
              onFileChange={(file) => file && ingestReport(file)}
              display="none"
              setLocalInputRef={setLocalInputRef}
            />
            <PoamTabActionButtons oneOrMorePoamsSelected={poamSelectionMap.size > 0} setOpenPanelName={setOpenPanelName} />
          </>
        )}
      </div>
      <div style={{ marginBottom: '1rem' }}>
        <Separator styles={separatorStyles} />
      </div>
      <PoamFilterBar
        poamColumns={poamColumns}
        updatePoamColumns={updatePoamColumns}
        poamFilters={poamFilters}
        updatePoamFilters={updatePoamFilters}
      />
      {filters.showClosed !== true ? (
        <>
          <div
            style={{
              display: 'flex',
              marginTop: '.5rem',
              marginBottom: '-.25rem',
              fontWeight: 600,
              zIndex: 2,
              position: 'relative',
              gap: '2rem',
            }}
          >
            <div>{`Displaying ${itemCount} of ${poamResults.totalCount} POA&Ms`}</div>
            <div>{`${poamSelectionMap.size} selected`}</div>
          </div>
          <PoamTable
            loading={loading}
            poams={poams}
            poamColumns={poamColumns}
            poamSelection={poamSelectionMap}
            setPoamSelection={setPoamSelectionMap}
            setSort={setSort}
            readonly={readonly}
            onScroll={onScroll}
          />
        </>
      ) : (
        <ClosedPoamTable poamColumns={poamColumns} />
      )}
      <DetailsPanel
        key={selectedPoamId}
        selectedPoamId={selectedPoamId}
        selectedPeriod={selectedPoamPeriod}
        onPoamSaved={refreshTable}
        isEditable={isEditable}
      />
      {openPanelName === 'autoRisk' && (
        <AutoRiskPanel close={() => setOpenPanelName(undefined)} updatePoamsRating={refreshTable} selectedPeriod={filters.period} />
      )}
      {openPanelName === 'manualRisk' && (
        <RiskPanel
          isOpen
          close={() => setOpenPanelName(undefined)}
          poamSelection={poamSelection}
          selectedPeriod={filters.period}
          updatePoamsRating={refreshTable}
        />
      )}
      {openPanelName === 'proposedClosure' && (
        <ProposedClosurePanel
          isOpen
          close={() => setOpenPanelName(undefined)}
          poams={poamResults.results ?? []}
          selectedPeriod={filters.period}
          updatePoamsClosure={refreshTable}
        />
      )}
      {(openPanelName === 'falsePositive' || openPanelName === 'operationalRequirement' || openPanelName === 'manualClosure') && (
        <EvidenceDeviationPanel
          key={crypto.randomUUID()}
          isOpen
          panelType={openPanelName as any}
          poamSelection={poamSelection}
          selectedPeriod={filters.period}
          updatePoams={refreshTable}
          close={() => setOpenPanelName(undefined)}
        />
      )}
      {openPanelName === 'bulkEdit' && (
        <BulkEditPanel
          key={crypto.randomUUID()}
          isOpen
          close={() => setOpenPanelName(undefined)}
          poamSelection={poamSelection}
          selectedPeriod={filters.period}
          updatePoams={refreshTable}
        />
      )}
      {manualPoams && manualPoams?.length > 0 && <ManualPoamModal poams={manualPoams} onClose={() => setManualPoams(undefined)} />}
    </div>
  );
};
