import React, { FunctionComponent, useContext } from 'react';
import { mergeStyles, PrimaryButton } from '@fluentui/react';
import { atoCopilotRoute } from '../../modules/routes/routes';
import copilotIcon from '../../assets/copilot.png';
import { AuthContext } from '../../components/authProvider/authContext';
import { COPILOT_READ, SITE_WIDE_SUBJECT } from '../../modules/constants';

const heroWrapperStyles = mergeStyles({
    width: '100%',
    margin: '2em 11em',
});

const heroRowStyles = mergeStyles({
    display: 'flex',
    alignItems: 'center',
    marginTop: '1em',
});

const subtitleStyles = mergeStyles({
    marginRight: '2em',
});

const iconStyles = {
    marginRight: '8px',
};

export const SecurityPlanManagerHeroContent: FunctionComponent = () => {
    const authContext = useContext(AuthContext);
    const isAtoCopilotReader = authContext.isAuthorized([{ operation: COPILOT_READ, subject: SITE_WIDE_SUBJECT }]);

    return (
        <div className={heroWrapperStyles}>
            <h1>System Security Plan Manager</h1>
            <div className={heroRowStyles}>
                <h2 className={subtitleStyles}>View and create a system and generate reports.</h2>
                {isAtoCopilotReader && (
                    <PrimaryButton href={atoCopilotRoute()}>
                        <img src={copilotIcon} alt="Copilot icon" style={iconStyles} />
                        Copilot for ATO
                    </PrimaryButton>
                )}
            </div>
        </div>
    );
};