import React, { FunctionComponent, useEffect, useState } from 'react';
import { Text, mergeStyles, Dropdown, IDropdownOption, Stack, IStackTokens, PrimaryButton, IIconProps, ActionButton, TextField, IDropdownStyles } from '@fluentui/react';
import { ProgressIndicator } from '@fluentui/react/lib/ProgressIndicator';
import { showError } from 'modules/messageBar/messageBar';
import { actionButtonStyles } from 'styles/actionButtonStyles';
import { pageChromeChildStyles } from 'styles/pageChromeChildStyles';
import { getAtoCopilotResponse } from 'modules/atoCopilotGet/atoCopilotGet';
import { logError } from 'modules/logging/logging';
import { getControlCatalogControlGroups, getControlGroup } from '../../modules/controlCatalogs/controlCatalogs';
import { getOrganizationControl } from '../../modules/organization/organization';


export const AtoCopilot: FunctionComponent = () => {

    const azureOrganizationId = "4793adf1-0b46-4ed0-ba91-bacc630e23aa";

    const dropdownStyle = mergeStyles({
        marginBottom: '2em',
    });

    const cardStyles = mergeStyles({
        boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.12), 0px 2px 4px 0px rgba(0, 0, 0, 0.14)',
        borderRadius: '8px',
        padding: '2em 3em',
        margin: '1em',
        height: 'auto',
        display: 'flex',
        flexDirection: 'column',
        gap: '1em',
    });

    const contentFlagStyle = mergeStyles({
        boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.12), 0px 2px 4px 0px rgba(0, 0, 0, 0.14)',
        borderRadius: '4px',
        padding: '6px',
        maxWidth: '18em', // subtract the left and right padding
        float: 'right',
        alignSelf: 'flex-end',
        backgroundColor: 'lightgrey',
    });

    const dropdownStackItemStyles = {
        root: {
            maxWidth: '300px',
        }
    }

    const dropdownStyles: Partial<IDropdownStyles> = {
        root: {
            margin: '1em 0 2em',
            flex: 1,
            maxWidth: '300px',
        },
        dropdown: {
            color: '#707070 !important',
            height: 'unset !important',
            width: '100%',
            maxWidth: '300px',
            ':focus': {
                border: '1px solid black !important',
            },
        },
        title: {
            borderRadius: '4px',
            border: '1px solid lightgrey !important',
            color: '#707070 !important',
            height: 'unset',
            padding: '0.25em 0.5em',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
        label: {
            fontWeight: '600 !important',
        },
        dropdownOptionText: {
            color: '#707070 !important',
        },
    };

    const horizontalGapStackTokens: IStackTokens = {
        childrenGap: 10,
        padding: 10,
    };

    const addFriendIcon: IIconProps = { iconName: 'Edit' };

    // State variables for user inputs and selections
    const [userAccessToken, setUserAccessToken] = useState<string>('');
    const [subscriptionIdOptions, setSubscriptionIdOptions] = useState<IDropdownOption[]>([]);
    const [controlFamilyOptions, setControlFamilyOptions] = useState<IDropdownOption[]>([]);
    const [controlOptions, setControlOptions] = useState<IDropdownOption[]>([]);

    // State variables for selected options
    const [selectedSubscriptionId, setSelectedSubscriptionId] = useState<string>('');
    const [selectedControlCatalogId, setSelectedControlCatalogId] = useState<string>('');
    const [selectedControlFamilyId, setSelectedControlFamilyId] = useState<string>('');
    const [selectedControlId, setSelectedControlId] = useState<string>('');

    // State variables for response and UI states
    const [copilotResponse, setCopilotResponse] = useState<string>('');
    const [editMode, setEditMode] = useState<boolean>(false);
    const [showText, setShowText] = useState<boolean>(false);
    const [isGenerating, setIsGenerating] = useState(false);
    const [generatedControl, setGeneratedControl] = useState<string>();

    const controlCatalogOptions: IDropdownOption[] = [
        { key: "NIST_SP_800-53_Rev4_Low", text: "NIST SP 800-53 LOW IMPACT BASELINE" },
        { key: "NIST_SP_800-53_Rev4_Moderate", text: "NIST SP 800-53 MODERATE IMPACT BASELINE" },
        { key: "NIST_SP_800-53_Rev4_High", text: "NIST SP 800-53 HIGH IMPACT BASELINE" },
    ];

    // Fetch and populate control family options when a control catalog is selected
    useEffect(() => {
        if (selectedControlCatalogId) {
            fetchControlFamilyOptions(selectedControlCatalogId);
        }
    }, [selectedControlCatalogId]);

    const fetchControlFamilyOptions = async (catalogId: string) => {
        try {
            const controlGroupsResult = await getControlCatalogControlGroups(catalogId);
            const options = controlGroupsResult
                .map((controlGroup: any) => ({
                    key: controlGroup.id ?? '',
                    text: `${controlGroup.title ?? ''} (${controlGroup.id?.toUpperCase()})`,
                }))
                .filter((controlGroup: any) => controlGroup.key !== '' && controlGroup.text !== '');

            setControlFamilyOptions(options);
        } catch (error) {
            logError("Failed to fetch control families.", error);
            showError('Failed to fetch control families. Please try again.');
        }
    };

    // Fetch and populate control options when a control family is selected
    useEffect(() => {
        const fetchControlOptions = async (controlFamilyId: string) => {
            try {
                const controlGroupResult = await getControlGroup(selectedControlCatalogId, controlFamilyId);
                const controls = controlGroupResult.controls || [];
                const options = controls
                    .map((control: any) => ({
                        key: control.id ?? '',
                        text: `${control.title ?? ''} (${control.id?.toUpperCase()})`,
                    }))
                    .filter((control: any) => control.key !== '' && control.text !== '');

                setControlOptions(options);
            } catch (error) {
                logError("Failed to fetch controls.", error);
                showError('Failed to fetch controls. Please try again.');
            }
        };
        if (selectedControlFamilyId) {
            fetchControlOptions(selectedControlFamilyId);
        }
    }, [selectedControlFamilyId, selectedControlCatalogId]);

    // Dropdown change handlers: update selected values when a selection changes

    const handleSubscriptionIdChange = (event: any, option?: IDropdownOption): void => {
        if (option) {
            setSelectedSubscriptionId(option.key as string);
        }
    };

    const handleControlCatalogChange = (event: any, option?: IDropdownOption): void => {
        if (option) {
            const catalogId = controlCatalogOptions.find((catalog) => catalog.text === option.text)?.key as string || '';
            setSelectedControlCatalogId(catalogId);
            // Reset dependent selections
            setSelectedControlFamilyId('');
            setControlFamilyOptions([]);
            setSelectedControlId('');
            setControlOptions([]);
        }
    };

    const handleControlFamilyChange = (event: any, option?: IDropdownOption): void => {
        if (option) {
            setSelectedControlFamilyId(option.key as string);
            setSelectedControlId('');
            setControlOptions([]);
        }
    };

    const handleControlChange = (event: any, option?: IDropdownOption): void => {
        if (option) {
            setSelectedControlId(option.key as string);
        }
    };

    // Handle Generate button click
    const handleGenerate = async () => {
        if (!selectedSubscriptionId) {
            showError('Please select a subscription ID.');
            return;
        }
        if (!selectedControlId) {
            showError('Please select a control to generate the implementation.');
            return;
        }
        if (!userAccessToken) {
            showError('Access token is missing. Please enter your access token and validate it.');
            return;
        }

        setIsGenerating(true);
        setShowText(true);
        setGeneratedControl(selectedControlId);

        const azureSsp = await getOrganizationControl(azureOrganizationId, selectedControlId);

        // Call the ATO Copilot API with the user's selections and access token
        getAtoCopilotResponse(selectedSubscriptionId, selectedControlId, userAccessToken, azureSsp.content as string)
            .then((response) => {
                setCopilotResponse(response.description);
                setIsGenerating(false);
            })
            .catch(() => {
                showError('Failed to generate implementation. Please try again later.');
                setIsGenerating(false);
            });
    };

    // Handle Validate Token button click
    const handleValidateToken = async () => {
        if (!userAccessToken) {
            showError('Please enter an access token.');
            return;
        }
        await fetchSubscriptions(userAccessToken);
    };

    // Fetch subscriptions using the user's access token
    const fetchSubscriptions = async (accessToken: string) => {
        try {
            const response = await fetch('https://management.azure.com/subscriptions?api-version=2022-12-01', {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();

            const subscriptions = data.value.map((sub: any) => ({
                subscriptionId: sub.subscriptionId,
                displayName: sub.displayName,
            }));

            if (subscriptions.length === 0) {
                showError('No subscriptions found for this access token.');
                return;
            }

            const maxLength = 50;

            setSubscriptionIdOptions(
                subscriptions.map((sub: any) => {
                    const displayText = `${sub.displayName} (${sub.subscriptionId})`;
                    const truncatedText = displayText.length > maxLength
                        ? `${displayText.slice(0, maxLength - 3)}...`
                        : displayText;
                    return {
                        key: sub.subscriptionId,
                        text: truncatedText,
                    };
                }) as IDropdownOption[],
            );
        } catch (error) {
            logError('Failed to fetch subscriptions.', error);
            showError('Failed to fetch subscriptions. Please check your access token and try again.');
        }
    };

    // Component to display the generated response
    const TextDisplay = (
        <div className={cardStyles}>
            {isGenerating ? (
                <ProgressIndicator label="Generating SSP implementation, this may take a few minutes..." />
            ) : (
                <>
                    {selectedControlId && (
                        <div className={cardStyles}>
                            <h2>{`${controlOptions.find((control) => control.key === generatedControl)?.text || ''}`}</h2>
                            {editMode ? (
                                <TextField
                                    multiline
                                    rows={5}
                                    value={copilotResponse}
                                    onChange={(e, newValue) => setCopilotResponse(newValue || '')}
                                    styles={{ root: { width: '100%' } }}
                                />
                            ) : (
                                <Text>{copilotResponse}</Text>
                            )}
                            <div style={{ marginTop: '10px' }}>
                                <ActionButton
                                    iconProps={addFriendIcon}
                                    onClick={() => setEditMode(!editMode)}
                                >
                                    {editMode ? 'Save' : 'Edit'}
                                </ActionButton>
                            </div>
                        </div>
                    )}
                </>
            )}
            <div className={contentFlagStyle}>
                <Text>AI-generated content may be incorrect</Text>
            </div>
        </div>
    );

    return (
        <div className={pageChromeChildStyles}>
            <h1>ATO Copilot</h1>
            <p>Generate Control Implementation Description for your System Security Plan (SSP) by making selections below.</p>
            <b>
                IMPORTANT NOTE: If your selected subscription contains more than 150 resources, the dev system may not be able to generate a Control Implementation Description.
                Please select a subscription with fewer resources.
            </b>

            {/* User Access Token Input */}
            <div className={cardStyles}>
                <p>
                    <b>How to get access token (it will expire in a few hours, so please refresh it if needed):</b>
                    <br />
                    1. Log in to Azure Portal and select Cloud Shell (PowerShell).
                    <br />
                    2. In the terminal, type <code>az account get-access-token</code>. This will return a JSON object.
                    <br />
                    3. Extract the value of the <code>accessToken</code> key, input it into the box below, and click Validate Token button.
                    <br />
                    Once validated, proceed with the dropdown menu selections.
                </p>
                <Stack horizontal tokens={horizontalGapStackTokens} verticalAlign="end">
                    <TextField
                        label="User Access Token"
                        placeholder="Enter your access token"
                        value={userAccessToken}
                        onChange={(event, newValue) => setUserAccessToken(newValue || '')}
                        required
                        styles={{ root: { flexGrow: 1 } }}
                    />
                    <Stack.Item>
                        <PrimaryButton
                            text="Validate Token"
                            className={actionButtonStyles}
                            onClick={handleValidateToken}
                        />
                    </Stack.Item>
                </Stack>
            </div>

            {/* Dropdown Menus for Selections */}
            <div className={cardStyles}>
                <Stack horizontal wrap tokens={horizontalGapStackTokens}>
                    <Stack.Item grow styles={dropdownStackItemStyles}>
                        <Dropdown
                            label="Subscription ID"
                            options={subscriptionIdOptions}
                            placeholder="Select an option"
                            required
                            className={dropdownStyle}
                            styles={dropdownStyles}
                            dropdownWidth="auto"
                            onChange={handleSubscriptionIdChange}
                            disabled={subscriptionIdOptions.length === 0}
                        />
                    </Stack.Item>

                    <Stack.Item grow styles={dropdownStackItemStyles}>
                        <Dropdown
                            label="Control Catalog"
                            options={controlCatalogOptions}
                            placeholder="Select an option"
                            required
                            className={dropdownStyle}
                            styles={dropdownStyles}
                            dropdownWidth="auto"
                            onChange={handleControlCatalogChange}
                            disabled={controlCatalogOptions.length === 0}
                        />
                    </Stack.Item>

                    <Stack.Item grow styles={dropdownStackItemStyles}>
                        <Dropdown
                            label="Control Family"
                            options={controlFamilyOptions}
                            placeholder="Select an option"
                            required
                            className={dropdownStyle}
                            styles={dropdownStyles}
                            dropdownWidth="auto"
                            selectedKey={selectedControlFamilyId}
                            onChange={handleControlFamilyChange}
                            disabled={controlFamilyOptions.length === 0}
                        />
                    </Stack.Item>

                    <Stack.Item grow styles={dropdownStackItemStyles} align="center">
                        <Dropdown
                            label="Control"
                            options={controlOptions}
                            placeholder="Select an option"
                            required
                            className={dropdownStyle}
                            styles={dropdownStyles}
                            dropdownWidth="auto"
                            selectedKey={selectedControlId}
                            onChange={handleControlChange}
                            disabled={controlOptions.length === 0}
                        />
                    </Stack.Item>

                    <Stack.Item align="center">
                        <PrimaryButton
                            text="Generate"
                            className={actionButtonStyles}
                            style={{ height: 'unset' }}
                            onClick={handleGenerate}
                            disabled={isGenerating}
                        />
                    </Stack.Item>
                </Stack>
            </div>

            {/* Display Generated Response */}
            {showText && TextDisplay}
        </div>
    );
}